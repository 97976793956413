// This handles the customer-facing purchase flow UI 

import {Controller} from "stimulus"

export default class extends Controller {

    static targets = ["couponCodeField"];

    static values = {}

    connect() {
        var couponWithParam = this.element.querySelector('.coupon-field-with-params')
        if ( window.location.href.includes('?coupon') && couponWithParam ){
            if (couponWithParam) couponWithParam.classList.remove('hidden')
            if (this.hasCouponCodeFieldTarget && window.location.href.split('?coupon=')[1]) {
                this.couponCodeFieldTarget.value = window.location.href.split('?coupon=')[1]
                this.element.querySelector('.verify-coupon-button').click()
                this.element.querySelector('.enter-coupon-link').click()
            }
        }else{
            if (couponWithParam) couponWithParam.classList.add('hidden')
        }
    }

    verifyCoupon(event) {
        var buttonTarget = event.target
        buttonTarget.disabled = true
        if (buttonTarget.getAttribute('data-disable-with')) {
            buttonTarget.setAttribute('data-btn-text', buttonTarget.innerHTML)
            buttonTarget.innerHTML = buttonTarget.getAttribute('data-disable-with')
        }
        event.preventDefault()
        var couponCode = this.couponCodeFieldTarget.value
        Rails.ajax({
            type: "get",
            url: '/verify_coupon?coupon_code=' + couponCode,
        })
    }
}