import { Controller } from "stimulus"

var timer = null;
export default class extends Controller {
  static targets = ["card", "name", "error", "form", "couponField", "couponApplied", "couponCodeMessage", "couponSuccessIcon", "couponErrorIcon", "submitButton", "purchaseForm", "fromCheckout"]

  connect(event) {
    if (this.hasPurchaseFormTarget || this.hasFromCheckoutTarget) {
      if (!metaTag) {
        var metaTag = document.createElement("meta");
      }
      metaTag.name = "stripe-key";
      var card_form = document.querySelector('.stripe-card-form') || document.querySelector('.card-form')
      metaTag.content = card_form.getAttribute('data-stripe-key');
      document.head.appendChild(metaTag);
    }

    let stripeMeta = document.querySelector('meta[name="stripe-key"]')
    if (stripeMeta === null) { return }

    let stripeKey = stripeMeta.getAttribute("content")
    var form = document.querySelector('form[data-stripe-account]');
    if (form) {
      let connectedAccountId = form.getAttribute('data-stripe-account');
      this.stripe = Stripe(stripeKey, { stripeAccount: connectedAccountId })
    } else {
      this.stripe = Stripe(stripeKey)
    }
    let elements = this.stripe.elements()

    // Setup Intents are used for adding new cards to be charged in the future
    this.setup_intent = this.data.get("setup-intent")

    // Payment intents are for processing payments that require action
    this.payment_intent = this.data.get("payment-intent")

    // Setup regular payments
    this.card = elements.create("card")
    this.card.mount(this.cardTarget)
    this.card.addEventListener("change", this.changed.bind(this))

    // Reveal coupon field & pre-fill it if ?coupon parameter is present in the URL
    var url_string = window.location.href;
    var url = new URL(url_string);
    var coupon_value = url.searchParams.get("coupon");
    if (coupon_value && this.hasCouponFieldTarget) {
      this.couponFieldTarget.classList.remove("hidden");
      if (coupon_value != "true") {
        this.couponFieldTarget.querySelector('input').value = coupon_value;
        this.validateCouponCode();
      }
    }
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ""
    }
  }

  keydown(event) {
    if (event.keyCode == 13) {
      // Catch Enter key's form submission and process as submit
      event.preventDefault()
      this.submit(event)
    }
  }

  submit(event) {
    this.submitButtonTarget.disabled = true // To prevent multiple form submits
    if (this.submitButtonTarget.getAttribute('data-disable-with')) {
      this.submitButtonTarget.setAttribute('data-btn-text', this.submitButtonTarget.innerHTML)
      this.submitButtonTarget.innerHTML = this.submitButtonTarget.getAttribute('data-disable-with')
    }

    if (this.nameTarget.value == "") {
      this.errorTarget.textContent = "Name on card is required."
      this.resetPurchaseButton()
      return
    }
    event.preventDefault()

    // One time payments
    if (this.payment_intent) {
      this.handleCardPayment()

      // Updating card with setup intent
    } else if (this.setup_intent) {
      this.setupNewCard()

      // Subscriptions simply tokenize the payment method and redirect to payment page if SCA required
    } else {
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        },
      }).then((result) => {
        if (result.paymentMethod == null) {
          this.errorTarget.textContent = "Card details are incomplete."
          this.resetSubscribeButton();
          return;
        }
        this.handlePaymentMethod(result.paymentMethod.id)
      })
    }
  }

  setupNewCard() {
    let data = {
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        }
      }
    }

    this.stripe.confirmCardSetup(this.setup_intent, data).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message
        this.resetPurchaseButton()
      } else {
        this.handlePaymentMethod(result.setupIntent.payment_method)
      }
    })
  }

  handlePaymentMethod(payment_method_id) {
    this.addHiddenField("account[processor]", "stripe")
    this.addHiddenField("account[card_token]", payment_method_id)

    Rails.fire(this.formTarget, "submit")

    this.changeSubscribeButton();
  }

  addHiddenField(name, value) {
    let hiddenInput = document.createElement("input")
    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", name)
    hiddenInput.setAttribute("value", value)
    this.formTarget.appendChild(hiddenInput)
  }

  handleCardPayment() {
    // Handle an existing payment that needs confirmation
    this.stripe.confirmCardPayment(this.payment_intent).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message

      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        Turbolinks.clearCache()
        Turbolinks.visit("/")
      }
    })
  }

  validateCouponCode() {
    var coupon_code = this.couponFieldTarget.querySelector('input').value
    if (coupon_code == null)
      return

    clearTimeout(timer);
    timer = setTimeout(function () {
      this.fetchResultAndUpdate(coupon_code);
    }.bind(this), 1000)

  }

  fetchResultAndUpdate(coupon_code) {
    var url = new URL(window.location.href);
    var plan_id = url.searchParams.get("plan");

    fetch(`/validate_coupon_code?coupon_code=${coupon_code}&plan=${plan_id}`).then(response => response.json())
      .then(data => {
        this.couponAppliedTarget.classList.remove('hidden')
        if (data.status === 'success') {
          this.couponCodeMessageTarget.innerHTML = data.message
          this.couponAppliedTarget.classList.add('text-secondary-600', 'bg-secondary-200');
          this.couponAppliedTarget.classList.remove('text-red-600', 'bg-red-100');
          this.couponSuccessIconTarget.classList.remove("hidden");
          this.couponErrorIconTarget.classList.add("hidden");
        } else {
          this.couponCodeMessageTarget.innerHTML = "Invalid coupon";
          console.log('Coupon error:');
          console.log(data.message);
          this.couponAppliedTarget.classList.remove('text-secondary-600', 'bg-secondary-200');
          this.couponAppliedTarget.classList.add('text-red-600', 'bg-red-100');
          this.couponSuccessIconTarget.classList.add("hidden");
          this.couponErrorIconTarget.classList.remove("hidden");
        }
      })
  }

  resetPurchaseButton() {
    this.submitButtonTarget.disabled = false
    if (this.submitButtonTarget.getAttribute('data-btn-text')) {
      this.submitButtonTarget.innerHTML = this.submitButtonTarget.getAttribute('data-btn-text')
    }
  }

  changeSubscribeButton() {
    var processing_button = document.querySelector('.processing-button');
    if (processing_button) {
      processing_button.classList.remove('hidden');
      this.submitButtonTarget.classList.add('hidden');
    }
  }

  resetSubscribeButton() {
    this.submitButtonTarget.disabled = false
    var processing_button = document.querySelector('.processing-button');
    if (processing_button) {
      processing_button.classList.add('hidden');
      this.submitButtonTarget.classList.remove('hidden');
      this.submitButtonTarget.classList.remove('opacity-50');
    }
  }
}
