import {Controller} from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
    static targets = ["field"]
    static values = {
        searchScope: String, conversationId: Number, currentUserContactId: Number
    }


    connect() {
        this.editor = this.fieldTarget.editor
        this.initializeTribute()
    }

    disconnect() {
        this.tribute.detach(this.fieldTarget)
    }

    initializeTribute() {
        var searchScope = this.searchScopeValue
        var conversationId = this.conversationIdValue
        var currentUserContactId = this.currentUserContactIdValue

        this.tribute = new Tribute({
            lookup: 'name', menuItemTemplate: function (item) {
                return `${item.original.content}`
            }, values: function fetchUsers(text, callback) {
                fetch(`/users/mentions.json?query=${text}&search_scope=${searchScope}&conversation_id=${conversationId}&current_user_contact_id=${currentUserContactId}`)
                    .then(response => response.json())
                    .then(users => callback(users))
                    .catch(error => callback([]))
            }, menuItemLimit: 10

        })
        this.tribute.attach(this.fieldTarget)
        this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
        this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    }

    replaced(e) {
        let mention = e.detail.item.original
        let attachment = new Trix.Attachment({
            sgid: mention.sgid, content: mention.content, contentType: 'application/vnd.my-application.mention'
        })
        this.editor.insertAttachment(attachment)
        this.editor.insertString(" ")
    }

    _pasteHtml(html, startPos, endPos) {
        let range = this.editor.getSelectedRange()
        let position = range[0]
        let length = endPos - startPos

        this.editor.setSelectedRange([position - length, position])
        this.editor.deleteInDirection("backward")
    }
}
