import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        this.refreshCloudflareToken()
        this.refreshGoogleRecaptchaToken()
    }
    refreshCloudflareToken(){
        const turnstileElements = this.element.querySelectorAll(".cf-turnstile");
        turnstile.ready(function () {
            turnstileElements.forEach(element => {
                element.innerHTML = ""
                turnstile.render(element, {
                    sitekey: element.dataset.sitekey,
                    callback: function (token) {
                        console.log(`Turnstile verification Success`);
                    }
                });
            });
        });
    }

    refreshGoogleRecaptchaToken() {
        console.log('entered')
        const recaptchaElements = document.querySelectorAll(".g-recaptcha");
        recaptchaElements.forEach(element => {
            setTimeout(function() {
            grecaptcha.ready(function() {
                setTimeout(function() {
                    grecaptcha.render(element, {
                        'sitekey': element.dataset.sitekey,
                        callback: function (token) {
                            console.log(`Recaptcha verification Success`);
                        }
                    });
                }, 1000);
            });
            }, 1000);
        });
    }
}